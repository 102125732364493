import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';

import tanquecito from 'tanquecito';
import axiosInterceptors from './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(tanquecito);
Vue.use(require('vue-moment'));

Vue.filter('toCurrency', (value) => {
  const val = (value / 1);
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});
Vue.filter('toFloatWithComa', (value) => {
  const val = (value / 1).toFixed(1).replace('.', ',');
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
});

axiosInterceptors();
new Vue({
  router,
  store,
  vuetify,
  tanquecito,
  render: (h) => h(App),
}).$mount('#app');
