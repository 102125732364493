<template>
  <v-app-bar
    fixed
    color="#FFFFFF"
    height="56px"
    max-height="56px"
    class="headerBox px-1"
  >
    <template v-if="pantalla === 'identificate'">
      <v-spacer />
      <v-app-bar-title>
        <v-img @click="Ir()" src="@/static/Edenred_Isotype.png" height="17px" width="68.73px"></v-img>
      </v-app-bar-title>
      <v-spacer />
    </template>
    <template v-else>
        <v-row no-gutters class="mx-0" justify="space-between" align="center">
          <v-col>
            <v-img @click="Ir()" src="@/static/Edenred_Isotype.png" height="56px" width="56px"></v-img>
          </v-col>
          <v-spacer></v-spacer>
          <v-col v-if="ruta !== 'home'" class="col-auto">
            <v-btn @click="$router.push('/')" style="min-width:0" class="pa-0 ma-0" text>
              <font-awesome-icon style="color:#074984" :icon="['far', 'barcode-read']" />
            </v-btn>
          </v-col>
          <v-col v-if="mostrar" class="text-end pl-4 col-auto" align-self="center">
            <font-awesome-icon icon="fa-regular fa-circle-user" class="primary--text text--darken-1 mr-2"/>
            <span class="subtitle-1 font-weight-bold text-uppercase black--text">{{userLoged()}}</span>
          </v-col>
        </v-row>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: 'HeaderComponent',
  props: [],
  data() {
    return {
      mostrar: false,
      btnEscanear: true,
    };
  },
  created() {
    console.log(this.$route, 'route');
  },
  computed: {
    idEquipo() {
      return localStorage.getItem('equipoActual');
    },
    pantalla() {
      return this.$store.state.pantalla;
    },
    ruta() {
      return this.$route.name;
    },
  },
  methods: {
    Ir() {
      if (this.idEquipo) {
        this.$router.push(`/equipo/${this.idEquipo}`);
      }
    },
    userLoged() {
      return localStorage.getItem('user');
    },
  },
  mounted() {
    setTimeout(() => {
      this.mostrar = true;
    }, 4000);
  },
};
</script>

<style lang='scss'>
.headerBox {
  box-shadow: 0 8px 10px rgba(175, 175, 175, 0.25) !important;
  z-index: 10 !important;
  text-align: center;
}
</style>
