import {
    required, email, max, min, confirmed, numeric,
   } from 'vee-validate/dist/rules';
   import { extend } from 'vee-validate';

   extend('required', {
     ...required,
     message: 'Completá este dato',
   });

   extend('numeric', {
     ...numeric,
     message: 'Solo se aceptan números',
   });
   extend('decimales', {
     validate: (value, { decimals = '*', separator = '.' } = {}) => {
       if (value === null || value === undefined || value === '') {
         return {
           valid: false,
         };
       }
       if (Number(decimals) === 0) {
         return {
           valid: /^-?\d*$/.test(value),
         };
       }
       const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
       const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

       return {
         valid: regex.test(value),
       };
     },
     message: 'Solo se aceptan números enteros o decimales separados por " . "',
   });
   extend('max', {
     ...max,
     message: 'Maximo {length} caracteres',
   });

   extend('min', {
     ...min,
     message: 'Mínimo {length} caracteres',
   });

   extend('min', {
     validate: (value) => value.length > 3,
     message: 'Mínimo 3 caracteres',
   });
   extend('numeros-puntos', {
    validate: (value) => /^\d+(\.\d{1,3})?$/.test(value),
    message: 'Solo se aceptan números enteros o decimales separados por " . ", maximo 3 decimal',
  });
  extend('cero', {
    // eslint-disable-next-line eqeqeq
    validate: (value) => value != 0,
    message: 'El valor no puede ser 0',
  });
   extend('min-10', {
     validate: (value) => value > 9,
     message: 'Mínimo 10 lts.',
   });
   extend('maximo', {
     validate(value, args) {
       return Number(value) <= Number(args.value);
     },
     params: ['value'],
     message: 'Máximo {value} lts.',
   });
   extend('email', {
     ...email,
     message: 'E-mail inválido.',
   });

   extend('confirmed', {
     ...confirmed,
     message: 'Las contraseñas deben ser iguales.',
   });

   extend('regexStringCompleto', {
     validate: (value) => /^[a-zA-Z0-9-._#+()'\sÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜäëïöüçÇ]+$/.test(value),
     message: 'Caracteres inválidos.',
   });
   extend('regexText', {
     validate: (value) => /^[a-zA-Z0-9-._#+()'\sÀÈÌÒÙàèìòùÁÉÍÓÚÝáéíóúýÂÊÎÔÛâêîôûÃÑÕãñõÄËÏÖÜäëïöüçÇ!?¿¡.,:;%&*]+$/.test(value),
     message: 'Caracteres inválidos.',
   });

   extend('regexPhone', {
     validate: (value) => /^\+*[0-9]{7,30}$/.test(value),
     message: 'Ingresa un número de teléfono valido',
   });
