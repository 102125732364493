import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    ticket: {
      cantidad: 0,
      token: 0,
    },
    delta: null,
    delay: null,
    // formToken
    idTransaction: null,
    unidad: null,
    cantidad: null,
    //
    transactionSelected: null,
    transaction: {},
    fechaTicket: null,
    pantalla: 'bienvenida',
    producto_seleccionado: {
      nombre: '',
      cupo: 0,
      num_contract: null,
    },
    /* eslint-disable global-require */

    stock1: [
      {
        id: 1,
        img: require('../static/AVGAS-100LL.jpg'),
        width: '111.81px',
        height: '20px',
        capacidad: 6000.0,
        volumen: 3010,
        temp: '25 °C',
        agua: 'Sin agua - No detectado',
        proxAbastecimiento: '',
      },
    ],
    // 1366
    stock2: [
      {
        id: 1,
        img: require('../static/diesel500.png'),
        width: '91px',
        height: '20px',
        capacidad: 2000.0,
        volumen: 1800,
        temp: '25 °C',
        agua: 'Sin agua - No detectado',
        proxAbastecimiento: '',
      },
    ],
    // bertotto
    stock3: [
      {
        id: 1,
        img: require('../static/diesel500.png'),
        width: '91px',
        height: '20px',
        capacidad: 3000.0,
        volumen: 1200,
        temp: '25 °C',
        agua: 'Sin agua - No detectado',
        proxAbastecimiento: '',
      },
    ],
    stock4: [
      {
        id: 1,
        img: require('../static/extravida_xvt.png'),
        width: '250px',
        height: '20px',
        capacidad: 3000.0,
        volumen: 1200,
        temp: '25 °C',
        agua: 'Sin agua - No detectado',
        proxAbastecimiento: '',
      },
    ],
    // edenred
    stock5: [
      {
        id: 1,
        img: require('../static/diesel500.png'),
        width: '91px',
        height: '20px',
        capacidad: 3000.0,
        volumen: 1200,
        temp: '25 °C',
        agua: 'Sin agua - No detectado',
        proxAbastecimiento: '',
      },
    ],
    productos: null,
    vehiculo: null,
    inputs_ticket: null,
    num_contract: Math.floor(1000 + Math.random() * 9000000),
  },
  getters: {},
  mutations: {
    SET_TICKET(state, data) {
      state.ticket = data;
    },
    SET_DELTA(state, data) {
      state.delta = data;
    },
    SET_DELAY(state, data) {
      state.delay = data;
    },
    SET_PANTALLA(state, data) {
      state.pantalla = data;
    },
    SET_CUPO_PRODUC_1(state, data) {
      Object.assign(state.productos[0], data);
    },
    SET_CUPO_PRODUC_2(state, data) {
      Object.assign(state.productos[1], data);
    },
    SET_VEHICULO(state, data) {
      state.vehiculo = data;
    },
    SET_CANTIDAD(state, data) {
      state.cantidad = data;
    },
    SET_PRODUCT_SELECT(state, data) {
      state.producto_seleccionado = data;
    },
    SET_TRANSACTION_SELECT(state, data) {
      state.transactionSelected = data;
    },
    SET_INPUTS(state, data) {
      state.inputs_ticket = data;
    },
    SET_TRANSACTION(state, data) {
      state.transaction = data;
    },
    SET_FECHA_TICKET(state, data) {
      state.fechaTicket = data;
    },
    SET_PRODUCTS(state, data) {
      state.productos = data;
    },
    SET_VOLUME_PRODUCT1(state, data) {
      state.stock[0].volumen = data;
    },
    SET_VOLUME_PRODUCT2(state, data) {
      state.stock[1].volumen = data;
    },
    SET_PROX_ABASTE_1(state, data) {
      state.stock[0].proxAbastecimiento = data;
    },
    SET_PROX_ABASTE_2(state, data) {
      state.stock[1].proxAbastecimiento = data;
    },

  },
  actions: {},
  modules: {},
});
