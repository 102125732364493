<template>
  <v-container>
    <v-row>
      <v-col>
        <v-speed-dial
        fixed
        bottom
        right
        :transition="transition"
        >
        <template v-slot:activator>
            <v-btn
              icon
              color="black"
              class="icon-help"
              small
            >
              <font-awesome-icon class="icon-help" icon="fa-solid fa-circle-question" />
            </v-btn>
        </template>
          <v-list dense class="list-help pb-2">
            <v-list-item-group
            >
            <v-btn v-for="item in items" :key="item.title"
            block
            text
            style="justify-content:left"
            class="mt-2 pt-2 pl-4 pb-1 mb-1 text-left"
            @click="ir(item.ruta)">
              {{item.title}}
            </v-btn>
            </v-list-item-group>
          </v-list>
        </v-speed-dial>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
    name: 'IconHelp',
    data() {
        return {
          transition: 'slide-y-reverse-transition',
          items: [
            {
              title: 'Sobre el producto',
              ruta: '/sobreProducto',
            },
            {
              title: 'Términos y condiciones',
              ruta: '/TyC',
            },
            {
              title: 'Acerca de',
              ruta: '/Info',
            },
          ],
        };
    },
    methods: {
      ir(ruta, pantalla) {
        this.$store.commit('SET_PANTALLA', pantalla);
        this.$router.push(ruta);
      },
    },
};
</script>

<style scoped>
.icon-help {
  width: 32px;
  height: 32px;
}
.icon-help.v-btn:before{
  background: none !important;;
}
.list-help{
  position: relative;
  /* left: -23vw; */
  margin-right: 11rem;
  top: 1vh;
  padding-right: 10px;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 0;
  background: #373536;
  border-radius: 5px;
}
.list-help .v-btn{
  letter-spacing: 0;
  font-family: 'Montserrat';
  text-transform: none;
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: auto !important;;
}
</style>
